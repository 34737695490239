import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const sconAPI = {
  getScons: topic.sconTopic + '/get_scons',
  getScons2: topic.sconTopic + '/get_scons2',
  getSconById: topic.sconTopic + '/get_scon_by_id',
  addScon: topic.sconTopic + '/add_scon',
  addSconOrder: topic.sconOrderTopic + '/add_scon',
  editScon: topic.sconTopic + '/edit_scon',
  deleteSconByIds: topic.sconTopic + '/delete_scon_by_ids',
  exportSconPdfById: topic.sconTopic + '/pdf/export_scon_pdf_by_id',
  exportInvPdf: topic.sconTopic + '/pdf/exportInvPdf',
  getSconPartBySuppId: topic.sconTopic + '/get_scon_part_by_supp_id',
  getSconMtrbBySuppId: topic.sconTopic + '/get_scon_mtrb_by_supp_id',
  getSconProdsByIds: topic.sconTopic + '/get_scon_prods_by_ids',
  changeCustNoIsRepeat: topic.sconTopic + '/changeCustNoIsRepeat',
  getSconPartBySconId: topic.sconTopic + '/get_scon_part_by_scon_id',
  getProduct: params => getNoCatch(`${topic.sconTopic}/getProduct`, params),
  getSconMtrb: params => getNoCatch(`${topic.sconTopic}/getSconMtrb`, params),
  getPodrSync: topic.sconTopic + '/getPodrSync',
  getSconChangehistory: topic.sconTopic + '/getSconChangehistory',
  exportPartStatistics: params => getNoCatch(`${topic.sconTopic}/export_partStatistics`, params),
  editSconPartCount: params => postNoCatch(`${topic.sconTopic}/editSconPartCount`, params),
  getSconPartCountList: params => getNoCatch(`${topic.sconTopic}/getSconPartCountList`, params),
  getSconProdCountList: params => getNoCatch(`${topic.sconTopic}/getSconProdCountList`, params),
  exportSconProdCount: params => getNoCatch(`${topic.sconTopic}/exportSconProdCount`, params),
  exportSconPartCount: params => getNoCatch(`${topic.sconTopic}/exportSconPartCount`, params),
  getSconProdToQarmaByProdid: params => postNoCatch(`${topic.sconTopic}/getSconProdToQarmaByProdid`, params),
  exportExcel: params => getNoCatch(`${topic.sconTopic}/exportSconTotalStatistics`, params),
  exportMonthSconTotalStatistics: params => getNoCatch(`${topic.sconTopic}/exportMonthSconTotalStatistics`, params),
  getSconTotalStatistics: params => getNoCatch(`${topic.sconTopic}/getSconTotalStatistics`, params),
  getMonthSconTotalStatistics: params => getNoCatch(`${topic.sconTopic}/getMonthSconTotalStatistics`, params),
  exportMonthSconProdTotalStatistics: params => getNoCatch(`${topic.sconTopic}/exportMonthSconProdTotalStatistics`, params),
  getSconProdTotalStatistics: params => getNoCatch(`${topic.sconTopic}/getSconProdTotalStatistics`, params),
  callFinance: params => getNoCatch(`${topic.sconTopic}/callFinance`, params),
  sconInvPush9006Total: params => getNoCatch(`${topic.sconTopic}/sconInvPush9006Total`, params),
  getSconInvPushList: params => getNoCatch(`${topic.sconTopic}/getSconInvPushList`, params),
  updateSconInvPush: params => getNoCatch(`${topic.sconTopic}/updateSconInvPush`, params),
  syncCatnip: params => getNoCatch(`${topic.sconTopic}/syncCatnip`, params),
  export_exors: params => getNoCatch(`${topic.sconTopic}/pdf/export_exors`, params),
  getSconOrderById: topic.sconOrderTopic + '/get_scon_by_id'
};
